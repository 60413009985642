@use "../../styles/variables.module.scss" as *;

// Container
.container {
  position: relative;
  display: inline-block;
  font-size: 0;
}

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

// List
.list {
  display: none;
  position: absolute;
  font-size: 1.4rem;
}

.listVisible {
  .list {
    display: inline-flex;
    z-index: 3;
  }
}

// Item
.item {
  word-break: keep-all;
}

.item__link {
  display: flex;
  align-items: center;
  color: #47506e;
  fill: #47506e;
  padding: 1rem 2.4rem;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  font-size: $font-regular-m-font-size;
  font-weight: normal;

  &:hover {
    background: $color-gray-lighten-2;
    color: $color-primary;
    fill: $color-primary;
  }
}
.withBorder {
  border-bottom: 1px solid $color-gray-lighten-1;
}

// Label
// NOTE: Dropdownのラベルといってもスタイルが物によって違うので、
// 機能的に最低限必要なスタイルだけを当てるのみにしている
.label {
  cursor: pointer;
}
