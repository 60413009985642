@use "../../styles/variables.module.scss" as *;

.posibilities {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1.6rem;
}
.posibilitiesItem {
  list-style-type: disc;
  margin-left: 1.2rem;
}
.linksToLogout {
  color: $color-primary;
  display: block;
}
