@use "../../styles/variables.module.scss" as *;

.HeadMessage {
  background-color: white;
  width: 100vw;
  padding: 1rem 0;
}
.HeadMessage--Attention {
  background-color: #f2f6ff;
  color: $color-primary;

  a {
    color: $color-primary;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.iconContainer {
  flex-shrink: 0;
}
