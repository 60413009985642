@use "../../styles/variables.module.scss" as *;

.root {
  // NOTE: .rootとsvgの間に余白が生まれてしまうのを防止
  & > svg {
    display: block;
  }
}

@mixin setFill($color) {
  svg path {
    fill: $color;
  }
  svg use {
    fill: $color;
  }
}

.colorGrayDarken2 {
  @include setFill($color-gray-darken-2);
}
.colorPrimary {
  @include setFill($color-primary);
}
.colorWhite {
  @include setFill(#fff);
}
.colorBlack {
  @include setFill($color-black);
}
.colorGreen {
  @include setFill(#7fbd3f);
}
.colorRed {
  @include setFill(#e25461);
}
.colorNotification {
  @include setFill($color-notification);
}

.colorGrayDarken1_5 {
  svg path {
    fill: $color-gray-darken-1_5;
  }
}
.colorGrayDarken1 {
  @include setFill($color-gray-darken-1);
}

.sizeSmall {
  width: 1.6rem;
  height: 1.6rem;
}
.sizeMediumSmall {
  width: 2rem;
  height: 2rem;
}
.sizeMedium {
  width: 2.4rem;
  height: 2.4rem;
}
.sizeLarge {
  width: 3.2rem;
  height: 3.2rem;
}
.sizeXLarge {
  width: 4rem;
  height: 4rem;
}
