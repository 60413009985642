@use "../../styles/variables.module.scss" as *;

.Header {
  height: 6.8rem;
  background-color: #fff;
  top: 0;
  z-index: 1;
}
.border {
  border-bottom: solid 1px #e4e4e4;
}
.Header__content {
  display: flex;
  align-items: center;
  height: 100%;
}
.Header__content--space-between {
  justify-content: space-between;
}
.Header__content--center > *:first-child,
.Header__content--center > *:last-child {
  flex: 1;
}
.Header__logo {
  height: 3.2rem;
}
.Header__right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $color-gray-darken-1_7;
  a:hover {
    color: $color-primary;
  }
}

.AccountDropdown__arrow {
  margin-left: 0.8rem;
  display: block;
}
@include max-screen($breakpoint-mobile) {
  .AccountDropdown__arrow {
    display: none;
  }
}

.AccountDropdown__list {
  padding: 0;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(29, 40, 50, 0.1);
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.5rem;
}
.AccountDropdown__listItem {
  &:hover {
    svg path {
      fill: $color-primary;
    }
    svg use {
      fill: $color-primary;
    }
  }
}
.AccountDropdown__howtoLink {
  display: flex;
  align-items: center;
}
.AccountDropdown__howto {
  margin-right: 0.4rem;
}
.HowtoLinkWrapper {
  display: flex;
  justify-content: flex-end;
}
.HowtoLink {
  display: flex;
  align-items: center;
}
.HowtoLink__text {
  display: inline-block;
  margin-right: 0.4rem; /* 5px くらいSVG側に余白入ってる */
  font-weight: bold;
  font-size: 1.2rem;
}
.profileLink {
  color: $color-black;
  display: block;
}
@include max-screen($breakpoint-mobile) {
  .HowtoLink__text {
    display: none;
  }
}
