@use "../../styles/variables.module.scss" as *;

.primary {
  :hover svg use,
  :hover svg path {
    fill: $color-primary;
  }
}
.primaryDarken {
  :hover svg use,
  :hover svg path {
    fill: darken($color-primary, 10%);
  }
}
