@use "../../styles/variables.module.scss" as *;

.root {
  padding-left: 14rem;
  padding-right: 14rem;
}

@include max-screen($breakpoint-tablet-wide) {
  .root {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
@include max-screen($breakpoint-mobile) {
  .root {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.colorWhite {
  background-color: #fff;
}
.colorGrayLighten3 {
  background-color: $color-gray-lighten-3;
}
.colorGrayLighten4 {
  background-color: #f9f9f9;
}

.fullWidth {
  width: 100%;
}
.fullHeight {
  height: 100%;
}

.shadow {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
}
