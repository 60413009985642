@use "../../styles/variables.module.scss" as *;

.FlashMessage {
  position: fixed;
  // NOTE: Modalのoverayが40なのでそれを超える値にしている
  z-index: 41;
  background-color: white;
  top: 0px;
  left: 0px;
  > button {
    color: #fff;
    display: block;
    width: 100%;
    padding: 1.4rem 1.6rem;
    text-align: left;
  }
  border-radius: 0.5rem;
  min-width: 54rem;
  max-width: 85%;

  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  opacity: 1;
}
.FlashMessage--Failed {
  background-color: rgba(244, 130, 129, 0.8);
  fill: #fff;
}

.FlashMessage--Success {
  background-color: rgba(126, 207, 164, 0.8);
  fill: #fff;
}
.FlashMessage__CloseButton {
  position: absolute;
  top: 0;
  right: 0;
}
.FlashMessage--Hidden {
  transition: opacity 0.5s;
  opacity: 0;
}
/* アニメーションキャンセル用 */
.FlashMessage--Visible {
  opacity: 1;
}
@include max-screen($breakpoint-mobile) {
  .FlashMessage {
    min-width: auto;
    width: 95%;
    text-align: left;
  }
}
