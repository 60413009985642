@use "./variables.module.scss" as *;

html {
  font-size: 62.5%; // 10px
}

body {
  @include font-regular-m;
  background: #fff;
}

html,
body {
  height: 100%;
  margin: 0;
}
button {
  background: transparent;
  border: none;
}
a {
  color: $color-black-lighten-2;
  text-decoration: none;
  &:hover {
    color: $color-primary;
    fill: $color-primary;
  }
}
a,
button {
  &:focus {
    outline: 0;
  }
  cursor: pointer;
}
p {
  line-height: 1.6;
}
p,
h1,
h2,
h3,
dl,
dt,
dd,
h4 {
  margin: 0;
}
input,
textarea {
  border: 1px solid $color-gray-lighten-1;
  box-sizing: border-box;
  &:focus {
    outline: 0;
    border: 1px solid $color-primary;
  }
  &:disabled {
    background: $color-gray-lighten-3;
  }
}
input[type="time"] {
  padding: 0 1.6rem;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
fieldset {
  padding: 1.6rem;
  border: 1px solid $color-gray-lighten-1;
  border-radius: 0.8rem;
}

@media only print {
  body {
    background: none;
  }
}

// react-modal
.ReactModal__Body--open {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

:root {
  --themeProgressBarHeight: 1.4rem;
  --themeProgressBarTrackColor: #dde0e8;
  --themeProgressBarTrackFillColor: #629af7;
}
