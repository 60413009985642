@use "../../styles/variables.module.scss" as *;

.Footer {
  height: 5rem;
  margin-bottom: 4rem;
  background-color: #fff;
  color: $color-gray-darken-2;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include max-screen($breakpoint-mobile) {
  .Footer {
    margin-buttom: 3.2rem;
  }
}
