.ErrorView {
  padding-top: 4rem;
  padding-bottom: 6rem;
}
.ErrorTitle {
  font-size: 2.2rem;
  font-weight: bold;
}
.ErrorView__Description {
  margin-top: 2rem;
}
.Description {
  line-height: 1.6;
  font-size: 1.4rem;
}
