@use "../../styles/variables.module.scss" as *;

/* variants */
.transparent {
  background-color: transparent;
  border: none;
}
.IconButton {
  margin: 0;
  padding: 0;
  // NOTE: アイコンはボタンの領域よりも小さい場合もあるので、flexboxで中央寄せにする
  display: flex;
  align-items: center;
  justify-content: center;
}
