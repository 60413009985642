@use "../../styles/variables.module.scss" as *;
.HowtoLinkWrapper {
  display: flex;
  justify-content: flex-end;
}
.HowtoLink {
  display: flex;
  align-items: center;
}
.HowtoLink:hover path {
  fill: $color-primary;
}
.HowtoLink__text {
  display: inline-block;
  margin-right: 0.4rem; /* 5px くらいSVG側に余白入ってる */
  font-weight: bold;
  font-size: 1.2rem;
}
.HowtoLink__text--primary {
  color: $color-primary;
}
.HowtoLink__text--darkGray2 {
  color: $color-gray-darken-2;
}

@include max-screen($breakpoint-mobile) {
  .HowtoLink__text {
    display: none;
  }
}
