.Flex {
  display: flex;
}

.justifyContentStart {
  justify-content: flex-start;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.justifyContentSpaceEvenly {
  justify-content: space-evenly;
}

.justifyContentStretch {
  justify-content: stretch;
}
.justifyContentBaseline {
  justify-content: baseline;
}
.directionColumn {
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}
.alignItemsBaseline {
  align-items: baseline;
}
.wrap {
  flex-wrap: wrap;
}

$marginRules: 4, 8, 10, 14, 16, 20, 22, 32;

@each $marginRule in $marginRules {
  .gap_horizontal_#{$marginRule} {
    > :not([hidden]) ~ :not([hidden]) {
      margin-left: #{$marginRule / 10}rem;
    }
  }
  .gap_vertical_#{$marginRule} {
    > :not([hidden]) ~ :not([hidden]) {
      margin-top: #{$marginRule / 10}rem;
    }
  }
  .marginTop_#{$marginRule} {
    margin-top: #{$marginRule / 10}rem;
  }
  .flexGap_#{$marginRule} {
    gap: #{$marginRule / 10}rem;
  }
}
